<se-fe-page seFeDataWidth="full">
  <se-fe-header seFePageHeader seFeDataSize="750" seFeDataTitle="Memberships">
  </se-fe-header>
  <se-fe-card seFePageContent class="launch-banner" *ngIf="usavPersonaEligibilities.length">
    <se-fe-card-content>
      <se-po-usav-launch [personaEligibilities]="usavPersonaEligibilities"></se-po-usav-launch>
    </se-fe-card-content>
  </se-fe-card>
  <se-fe-card seFePageContent>
    <se-fe-card-content>
      <se-fe-chip-group class="chip-filters">
        <se-fe-chip [seFeDataText]="personFilterTitle" [seFeMenuTrigger]="personMenuOptions.name"
          [seFeDataSelected]="displayPersonaFilterCount > 1">
          <se-fe-avatar seFeChipAvatar *ngIf="showChipAvatar" seFeDataType="persona"
            [seFeDataIdentity]="chipPersonaOpts.identity" [seFeDataImgUrl]="chipPersonaOpts.imgURL" seFeDataSize="100">
          </se-fe-avatar>
          <se-fe-indicator-dot *ngIf="displayPersonaFilterCount > 1" seFeChipIndicatorDot
            [seFeDataValue]="displayPersonaFilterCount"></se-fe-indicator-dot>
        </se-fe-chip>
        <se-fe-menu [seFeDataOptions]="personMenuOptions" class="membership-menu">
          <div seFeMenuContent class="membership-menu-content">
            <label>Person</label>
            <form *ngIf="ready" [formGroup]="form" (ngSubmit)="applyFilters()" novalidate>
              <se-fe-list formGroupName="personFilters">
                <se-fe-list-item *ngFor="let profile of profiles" class="checkbox-row">
                  <se-fe-checkbox class="checkbox-row-item" [formControlName]="profile.persona.id.toString()"
                    [seFeDataHiddenLabel]="true" [seFeDataOptions]="personOptions[profile.persona.id].checkboxOpts">
                  </se-fe-checkbox>
                  <se-fe-avatar class="checkbox-row-item" seFeDataType="persona"
                    [seFeDataIdentity]="personOptions[profile.persona.id].checkboxOpts.label"
                    [seFeDataImgUrl]="personOptions[profile.persona.id].image" seFeDataSize="200"
                    [seFeDataAriaLabel]="personOptions[profile.persona.id].checkboxOpts.label">
                  </se-fe-avatar>
                  <span>{{ personOptions[profile.persona.id].checkboxOpts.label }}</span>
                </se-fe-list-item>
              </se-fe-list>
              <se-fe-button seFeDataEmphasis="high" [seFeDataFullWidth]="true" seFeDataType="submit" class="apply-filter-button">
                Apply Filters
              </se-fe-button>
              <se-fe-button [seFeDataFullWidth]="true" (click)="clearFilter('personFilters')">
                Clear All Filters
              </se-fe-button>
            </form>
          </div>
        </se-fe-menu>
        <se-fe-chip [seFeDataText]="statusFilterTitle" [seFeMenuTrigger]="statusMenuOptions.name"
          [seFeDataSelected]="displayStatusFilterCount > 1">
          <se-fe-indicator-dot *ngIf="displayStatusFilterCount > 1" seFeChipIndicatorDot
            [seFeDataValue]="displayStatusFilterCount"></se-fe-indicator-dot>
        </se-fe-chip>
        <se-fe-menu [seFeDataOptions]="statusMenuOptions" class="membership-menu">
          <div seFeMenuContent class="membership-menu-content">
            <label>Status</label>
            <form [formGroup]="form" (ngSubmit)="applyFilters()" novalidate>
              <se-fe-checkboxes class="membership-filter-checkbox" formControlName="statusFilters"
                [seFeDataOptions]="statusCheckOptions">
              </se-fe-checkboxes>
              <se-fe-button seFeDataEmphasis="high" [seFeDataFullWidth]="true" seFeDataType="submit" class="apply-filter-button">
                Apply Filters
              </se-fe-button>
              <se-fe-button [seFeDataFullWidth]="true" (click)="clearFilter('statusFilters')">
                Clear All Filters
              </se-fe-button>
            </form>
          </div>
        </se-fe-menu>
      </se-fe-chip-group>
      <se-fe-table-wrapper>
        <table seFeTable seFeTableSort title="Membership Table">
          <ng-container seFeColumnDef="membership">
            <th seFeDataRef="membership" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Membership
            </th>
            <td seFeCell *seFeCellDef="let row">
              <se-fe-grid seFeGridOpts="snug middle">
                <se-fe-grid-cell seFeGridOpts="shrink">
                  <se-fe-avatar class="membership-table-avatar" seFeDataType="org" [seFeDataIdentity]="row.org.name"
                    [seFeDataImgUrl]="row.org.org_logo?.[0]?.url || ''" seFeDataSize="200">
                  </se-fe-avatar>
                </se-fe-grid-cell>
                <se-fe-grid-cell seFeGridOpts="grow">
                  <div seFeLink="low" [seFeSidePanelTrigger]="isSidePanelTrigger(row)"
                    (click)="openPersonaEligibilitySidePanel(row)">{{ row.title }}</div>
                  <div class="membership-subtitle">{{ row.subtitle }}</div>
                </se-fe-grid-cell>
              </se-fe-grid>
            </td>
          </ng-container>
          <ng-container seFeColumnDef="status">
            <th seFeDataRef="status" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Status
            </th>
            <td seFeCell *seFeCellDef="let row">
              <se-fe-chip [seFeDataText]="row.chip.title" [seFeDataStyle]="row.chip.color"
                seFeDataIconLeft="dot_fill"></se-fe-chip>
            </td>
          </ng-container>
          <ng-container seFeColumnDef="organization">
            <th seFeDataRef="organization" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Organization
            </th>
            <td seFeCell *seFeCellDef="let row">
              <div>{{ row.org.name }}</div>
              <div class="membership-subtitle">Membership ID - {{row.personaEligibility.membership_number}}</div>
            </td>
          </ng-container>
          <ng-container seFeColumnDef="validityDates">
            <th seFeDataRef="validityDates" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Valid Date Range
            </th>
            <td seFeCell *seFeCellDef="let row">{{ row.validityDates }}</td>
          </ng-container>
          <ng-container seFeColumnDef="person">
            <th seFeDataRef="person" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Person
            </th>
            <td seFeCell *seFeCellDef="let row">
              <se-fe-grid seFeGridOpts="snug middle">
                <se-fe-grid-cell seFeGridOpts="shrink">
                  <se-fe-avatar class="membership-table-avatar" seFeDataType="persona"
                    [seFeDataIdentity]="row.personaEligibility.legal_or_profile_name" [seFeDataImgUrl]="row.profileImage"
                    seFeDataSize="200">
                  </se-fe-avatar>
                </se-fe-grid-cell>
                <se-fe-grid-cell seFeGridOpts="grow">
                  <a seFeLink="low" [href]="'user/household?id=' + row.personaEligibility.persona_id">
                    {{ row.personaEligibility.legal_or_profile_name }}
                  </a>
                </se-fe-grid-cell>
              </se-fe-grid>
            </td>
          </ng-container>
          <tr seFeHeaderRow *seFeHeaderRowDef="displayedColumns"></tr>
          <tr seFeRow *seFeRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </se-fe-table-wrapper>
      <se-fe-spinner class="membership-table-spinner" *ngIf="!listItems" [seFeDataSize]="900" [seFeDataCenter]="true">
      </se-fe-spinner>
      <se-fe-empty-state seFeDataTitle="Looks like you don't have any memberships yet!"
        *ngIf="listItems && !listItems.length">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_whistle" seFeDataSize="950"></se-fe-icon>
        Once you have purchased a membership for someone in your household the membership information will appear
        here.
      </se-fe-empty-state>
      <se-fe-empty-state [seFeDataTitle]="refreshEmptyStateTitle"
        *ngIf="listItems && listItems.length && !dataSource.data.length">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_search" seFeDataSize="950"></se-fe-icon>
        Try adjusting your filters to find what you're looking for.
        <se-fe-button seFeEmptyStateAction (click)="clearAllFilters()">
          <se-fe-icon seFeDataName="refresh" seFeDataSize="600" seFeDataColor="primary"></se-fe-icon>
          <span class="refresh">Refresh</span>
        </se-fe-button>
      </se-fe-empty-state>
      <se-po-persona-eligibility-side-panel #personaEligibilitySidePanel
        [profile]="sidePanelProfile"
        [eligibilityRuleSetId]="sidePanelEligibilityRuleSetId"
        [organizationId]="sidePanelOrganizationId"
        (closePanel)="onPersonaEligibilitySidePanelClose()">
      </se-po-persona-eligibility-side-panel>
    </se-fe-card-content>
  </se-fe-card>
</se-fe-page>
