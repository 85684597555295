import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeFormControlHelpModule } from 'se-fe-form-control-help'
import { SeFeIconModule, seFeIconSpotJersey } from 'se-fe-icon'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SharedUiPersonaInfoModule } from '@se-po/shared-ui-persona-info'
import { SharedUiHighlightLabelModule } from '@se-po/shared-ui-highlight-label'

import { AddChildProfileComponent } from './add-child-profile/add-child-profile.component'

@NgModule({
  declarations: [AddChildProfileComponent],
  exports: [AddChildProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeButtonModule,
    SeFeFormControlHelpModule,
    SeFeIconModule.include([seFeIconSpotJersey]),
    SeFeSpinnerModule,
    SharedUiHighlightLabelModule,
    SharedUiPersonaInfoModule,
  ],
})
export class SharedUiAddChildProfileModule { }
