import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'

import { OnInit } from '@angular/core'
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { GuardianAccessComponent } from '@se-po/se-my-sportsengine-feature-guardian-access'
import { HouseholdPersonasService } from '@se-po/shared-data-access-services'
import { catchError, forkJoin, Observable, of, zip } from 'rxjs'
import { SeFeFormService } from 'se-fe-form-service'
import { SeFeToastService } from 'se-fe-toast'
import { CentralServiceResources as cs } from 'se-resource-types'
import { RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { NewPersonSubmitter } from '../new-person-submitter-service'
@Component({
  selector: 'se-po-add-guardian',
  templateUrl: './add-guardian.component.html',
  styleUrls: ['./add-guardian.component.scss'],
})

export class AddGuardianComponent implements OnInit {
  @Input() public profiles: UserProfile[] = []
  @Input() public guardians: RelatedGuardiansResult[] = []
  @Output() public guardianInvited: EventEmitter<void> = new EventEmitter()
  @ViewChild('guardianAccess') public guardianAccess: GuardianAccessComponent

  public error: string
  public form: UntypedFormGroup
  public allInvites: any[] = []

  constructor(
    private formBuilder: FormBuilder,
    private formService: SeFeFormService,
    private householdPersonasService: HouseholdPersonasService,
    private newPersonService: NewPersonSubmitter,
    private toastService: SeFeToastService
  ) {
  }

  public ngOnInit(): void {
    this.getAllInvites()
    this.createForm()
  }

  public getAllInvites(): void {
    const inviteRequests = this.profiles.map(profile => this.householdPersonasService.findPersonaGuardianInvites(profile.persona.id))
    forkJoin(inviteRequests).subscribe(invites => {
      this.allInvites = invites.flat()
    })
  }

  public success(): void {
    this.toastService.success(`Invitation sent to ${this.form.value.email}`)
    this.guardianInvited.emit()
   }

  public createForm(): void {
    this.form = this.formBuilder.group(
      {
        email: ['', Validators.required]
      }
    )
  }

  public submit(): void {
    this.error = null
    const formValid = this.formService.isValid(this.form)
    const guardianAccessValid = this.guardianAccess.isValid()

    if (!formValid || !guardianAccessValid) {
      return
    }
    const selectedPersonaIds = this.guardianAccess.value

    zip(...selectedPersonaIds.map(item => this.submitOne(item.id, item.access))).subscribe(guardianships => {
      const successGuardianships = guardianships.filter(guardian => !!guardian)
      if (successGuardianships.length === guardianships.length) {
        this.success()
      } else {
        this.error = "Action can't be completed, please try again."
        this.toastService.error('Action can\'t be completed, please try again.')
      }
    })
  }

  public submitOne(personaId: number, access: string): Observable<cs.Households.PersonaGuardianInvite> {
    return this.newPersonService.createGuardian(
      access,
      this.form.value.email,
      personaId,
      'mySe'
    ).pipe(
      catchError((err: HttpErrorResponse) => of(null))
    )
  }
}
