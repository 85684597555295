import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeModalModule } from 'se-fe-modal'

import { SeMySportsengineFeatureGuardianAccessModule } from '@se-po/se-my-sportsengine-feature-guardian-access'

import { ParentGrantAccessModalComponent } from './parent-grant-access-modal/parent-grant-access-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeModalModule,
    SeMySportsengineFeatureGuardianAccessModule,
  ],
  declarations: [
    ParentGrantAccessModalComponent
  ],
  exports: [
    ParentGrantAccessModalComponent
  ],
})
export class SeMySportsengineFeatureParentGrantAccessModalModule {}
