import { Component, Input } from '@angular/core'

@Component({
  selector: 'se-po-highlight-label',
  template: '<span [class]="theme">{{ label }}</span>',
  styleUrls: ['./highlight-label.component.scss'],
})
export class HighlightLabelComponent {
  @Input() public theme: string
  @Input() public label: string
}
