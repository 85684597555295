import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeMySportsengineFeatureGuardianAccessModule } from '@se-po/se-my-sportsengine-feature-guardian-access'
import { SharedUiAddChildProfileModule } from '@se-po/shared-ui-add-child-profile'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeHeaderModule } from 'se-fe-header'
import {
  SeFeIconModule,
  seFeIconSpotFamily,
  seFeIconSpotJersey
} from 'se-fe-icon'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeProgressBarModule } from 'se-fe-progress-bar'
import { AddGuardianComponent } from './add-guardian/add-guardian.component'
import { NewPersonModalComponent } from './new-person-modal/new-person-modal.component'
import { SelectRelationshipComponent } from './select-relationship/select-relationship.component'
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeAlertModule,
    SeFeButtonModule,
    SeFeHeaderModule,
    SeFeIconModule.include([seFeIconSpotFamily, seFeIconSpotJersey]),
    SeFeModalModule,
    SeFeProgressBarModule,
    SeMySportsengineFeatureGuardianAccessModule,
    SharedUiAddChildProfileModule,
    SharedUiEmailModule
  ],
  declarations: [
    NewPersonModalComponent,
    SelectRelationshipComponent,
    AddGuardianComponent,
  ],
  exports: [NewPersonModalComponent]
})
export class SeMySportsengineFeatureNewPersonModalModule {}
