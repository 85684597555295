<se-fe-modal #modal>
  <div seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="Add Person">
    </se-fe-modal-header>
    <div>
      <se-fe-progress-bar
        [seFeDataSegmented]="true"
        [seFeDataCompleted]="progressComplete"
        seFeDataTotal="2">
      </se-fe-progress-bar>
    </div>
    <div class="new-person-progress-text">{{ progressComplete }} of 2 steps complete</div>
  </div>
  <div seFeModalContent>
    <div [ngSwitch]="step">
      <se-po-select-relationship #selectRelationship *ngSwitchCase="stepEnum.SelectRelationship">
      </se-po-select-relationship>
      <ng-container *ngSwitchCase="stepEnum.AddChild">
        <se-po-add-child-profile #childProfile></se-po-add-child-profile>
      </ng-container>
      <ng-container *ngSwitchCase="stepEnum.AddGuardian">
        <se-po-add-guardian
          #addGuardian
          [guardians]="guardians"
          [profiles]="profiles"
          (guardianInvited)="modal.close()">
        </se-po-add-guardian>
      </ng-container>
    </div>
  </div>
  <div seFeModalFooter #modalFooter class="footer-buttons">
    <se-fe-button-group>
      <se-fe-button
      seFeDataEmphasis="low"
      (click)="modal.close()">Cancel</se-fe-button>
      <se-fe-button
      seFeDataEmphasis="high"
      (click)="continue()">{{ continueLabel }}</se-fe-button>
    </se-fe-button-group>
    <se-fe-back-button
      (click)="back()"
      *ngIf="step">Back</se-fe-back-button>
  </div>
</se-fe-modal>
