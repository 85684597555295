import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { GuardianAccessComponent } from '@se-po/se-my-sportsengine-feature-guardian-access'
import { catchError, Observable, of, zip } from 'rxjs'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { CentralServiceResources as cs } from 'se-resource-types'
import { PersonaAccount, RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { CreateGuardianInviteSubmitter } from './create-guardian-invite-service'

@Component({
  selector: 'se-po-parent-grant-access-modal',
  templateUrl: './parent-grant-access-modal.component.html',
  styleUrls: ['./parent-grant-access-modal.component.scss'],
})
export class ParentGrantAccessModalComponent {
  @ViewChild('addModal') public addModal: SeFeModalComponent
  @ViewChild('modalFooter') public modalFooter: SeFeModalFooterComponent
  @ViewChild('guardianAccess') public guardianAccess: GuardianAccessComponent
  @Output() public guardianAdded: EventEmitter<void> = new EventEmitter()
  @Input() public accountItems: any[] = []
  @Input() public inviteItems: any[] = []
  @Input() public profile: PersonaAccount
  @Input() public profiles: UserProfile[] = []
  @Input() public guardians: RelatedGuardiansResult[] = []
  @Input() public allInvites: any[] = []

  public displayList = []
  public errors = []
  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.addModal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      }
    },
  ]
  public submitError = false

  constructor(
    public formBuilder: FormBuilder,
    protected guardianSubmitter: CreateGuardianInviteSubmitter,
    public toastService: SeFeToastService,
  ) {
  }

  public get enabledAddableChildren(): any[] {
    return this.guardianAccess.enabledAddableChildren
  }

  public open(): void {
    this.submitError = false
    this.addModal.open(null)
  }

  public submit(): void {
    this.submitError = false
    if (!this.guardianAccess.isValid()) {
      this.modalFooter.cancelSpinner()
      return
    }

    const selectedPersonaIds = this.guardianAccess.value

    zip(...selectedPersonaIds.map(item => this.submitOne(item.id, item.access))).subscribe(guardianships => {
      const successGuardianships = guardianships.filter(guardian => !!guardian)
      if (successGuardianships.length === guardianships.length) {
        this.success(this.profile.user.email_address.address)
      } else {
        this.submitError = true
        this.toastService.error('Action can\'t be completed, please try again.')
        this.modalFooter.cancelSpinner()
      }
    })
  }

  public submitOne(personaId: number, access: string): Observable<cs.Households.PersonaGuardianInvite> {
    return this.guardianSubmitter.create(
      access,
      this.profile.user.email_address.address,
      personaId,
      'mySe'
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        this.modalFooter.cancelSpinner()
        return of(null)
      })
    )
  }

  public success(email: string): void {
    this.toastService.success(`Invitation(s) sent to ${email}`)
    this.addModal.close()
    this.guardianAdded.emit()
  }

  public closeModal(): void {
    this.guardianAccess.resetForm()
    this.addModal.close()
  }
}
