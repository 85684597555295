import { AfterViewInit, Component, forwardRef, Injector, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { AddressValidationService } from '@se-po/shared-data-access-validators'
import { FormGroupValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { Subscription } from 'rxjs'
import { SeFeTranslationsLoader } from 'se-fe-translations'
import { Address } from 'se-resource-types/dist/lib/UserService'

@Component({
  selector: 'se-po-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    }
  ]
})
export class AddressComponent extends FormGroupValueAccessorDirective implements OnInit, AfterViewInit, OnDestroy {
  public country: string
  public fieldConfigs: any
  public form: UntypedFormGroup
  public stateLabel: string

  private _addressValidator: AddressValidationService
  private _subscriptions: Subscription[] = []

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
    private translateService: TranslateService)
  {
    super(injector)
  }

  public ngOnInit(): void {
    super.ngOnInit()
    this.initTranslations()
  }

  public registerOnChange(fn: (val: Address) => void): void {
    this._subscriptions.push(this.form.valueChanges.subscribe((val: Address) => {
      const isEmpty = Object.values(val).filter(x => !!x).length === 0
      const address = isEmpty ? null : val
      fn(address)
    }))
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this.initCountry()
    this.initValidations()
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-address/assets/translations').then(loaded => {
      const fieldConfigs = {}
      const fields = ['street_1', 'city', 'zip']
      fields.forEach(field => {
        const errorMessages: any = {
          required: this.translateService.instant(`SE_PO_UI_ADDRESS.ERRORS.${field}Required`)
        }
        if(field === 'zip') {
          errorMessages.invalidZip = errorMessages.required
        }
        fieldConfigs[field] = {
          label: this.translateService.instant(`SE_PO_UI_ADDRESS.LABELS.${field}`),
          errorMessages
        }
      })
      this.fieldConfigs = fieldConfigs
      this.loaded = true
    })
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      country: [''],
      street_1: [''],
      city: [''],
      state_or_province: [''],
      zip: ['']
    })
    this.initCountry()
    this.formatZip()
  }

  public setForm(value: Address): void {
    let country: string
    switch (value?.country) {
      case 'USA':
      case 'United States':
        country = 'US'
        break
      case 'Canada':
        country = 'CA'
        break
      default:
        country = value?.country
    }

    this.form.setValue({
      country: country || '',
      street_1: value?.street_1 || '',
      city: value?.city || '',
      state_or_province: value?.state_or_province || '',
      zip: value?.zip || ''
    })
  }

  public formatZip(): void {
    this.subscriptions.push(this.form.get('zip')?.valueChanges.subscribe((value) => {
      if(this.country === 'CA') {
        const cleanValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '') // Remove non-alphanumeric characters
        if(cleanValue.length > 3) {
          const length = cleanValue.length
          this.form.get('zip')?.setValue(`${cleanValue.slice(0, 3)} ${cleanValue.slice(3, length)}`, { emitEvent: false })
        } else {
          this.form.get('zip')?.setValue(cleanValue, { emitEvent: false })
        }
      }
    }))
  }

  private initValidations(): void {
    // making sure that we apply the required validation that might be on
    // the overarching formControl for this component to the underlying form group.
    if(this.formControl.hasValidator(Validators.required) && !this.form.hasValidator(Validators.required)) {
      this.form.addValidators(Validators.required)
      this.form.updateValueAndValidity()
    }
    this._subscriptions.push(this.formControl.statusChanges.subscribe(status => {
      if(this.formControl.hasValidator(Validators.required) && !this.form.hasValidator(Validators.required)) {
        this.form.addValidators(Validators.required)
        this.form.updateValueAndValidity()
      }
      if(!this.formControl.hasValidator(Validators.required) && this.form.hasValidator(Validators.required)) {
        this.form.removeValidators(Validators.required)
        this.form.updateValueAndValidity()
      }
    }))
    this._addressValidator = new AddressValidationService(this.form)
  }

  private initCountry(): void {
    this.setCountry(this.form.get('country').value)
    this._subscriptions.push(this.form.get('country').valueChanges.subscribe(country => {
      this.setCountry(country)
    }))
  }

  private setCountry(country: string): void {
    this.country = country
    this.stateLabel = country === 'CA' ? 'SE_PO_UI_ADDRESS.LABELS.province' : 'SE_PO_UI_ADDRESS.LABELS.state'
    if (this.form.value.zip) {
      this.form.get('zip').updateValueAndValidity()
    }
  }
}
