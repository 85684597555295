import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HighlightLabelComponent } from './highlight-label/highlight-label.component'

@NgModule({
  imports: [CommonModule],
  declarations: [HighlightLabelComponent],
  exports: [HighlightLabelComponent],
})
export class SharedUiHighlightLabelModule {}
