<se-fe-alert
  *ngIf="error"
  style="margin-top: 12px;"
  seFeDataType="error"
  [seFeDismissible]="false">
    Invitation Not Sent
  <se-fe-alert-subheader>
    {{ error }}
  </se-fe-alert-subheader>
</se-fe-alert>
<div>
  Invite an adult to create an account and join your household.
</div>
<form
  *ngIf="form"
  [formGroup]="form"
  novalidate>
  <div class="form-field">
    <se-po-email
      formControlName="email"
      seFeDataLabel="Email">
    </se-po-email>
  </div>
</form>
<div>
  Select who you would like to grant this adult access to:
</div>
<se-po-guardian-access
  #guardianAccess
  [allInvites]="allInvites"
  [guardians]="guardians"
  [profiles]="profiles">
</se-po-guardian-access>
