<div [formGroup]="form">
  <div class="radioCard">
    <input type="radio"
    class="radioButton"
    formControlName="selectRelationship"
    id="add_child"
    value="addChild"
    checked>
    <label class="radioLabel" for="add_child">
      <se-fe-header
        class="radioHeader"
        size="600"
        seFeDataTitle="Child"
        seFeDataSubtitle="Create a profile within your household for someone under the age of 18.">
        <se-fe-icon seFeHeaderVisual seFeDataName="spot_jersey" seFeDataSize="800"></se-fe-icon>
      </se-fe-header>
    </label>
  </div>
  <div class="radioCard">
    <input type="radio"
      class="radioButton"
      formControlName="selectRelationship"
      id="add_guardian"
      value="addGuardian"
      checked>
    <label class="radioLabel" for="add_guardian">
      <se-fe-header
        class="radioHeader"
        size="600"
        seFeDataTitle="Adult"
        seFeDataSubtitle="Invite an adult to create an account and manage members of your household.">
        <se-fe-icon seFeHeaderVisual seFeDataName="spot_family" seFeDataSize="800"></se-fe-icon>
      </se-fe-header>
    </label>
  </div>
</div>
