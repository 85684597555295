import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { ApiResponse } from 'se-resource-types/dist/lib/common'
import { Persona, VerifiedInformation } from 'se-resource-types/dist/lib/UserService'

import { nginV2Headers, PersonaInfo } from '@se-po/shared-data-access-models'

@Injectable({
  providedIn: 'root'
})
export class UserPersonaService {
  public endpoint = 'personas'
  private _mineSubject: BehaviorSubject<Persona[]> = new BehaviorSubject<Persona[]>(null)
  private _mine: Persona[]

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public find(personaId: string, params?: any): Observable<Persona> {
    const url = `${this.endpoint}/${personaId}`
    return this.api.get<Persona>(url, { params, headers: nginV2Headers }).pipe(
      map(response => response.result)
    )
  }

  public create(userId: number, data: PersonaInfo): Observable<Persona> {
    const createProfileData: any = {
      owner_user_id: userId,
      first_name: data.name?.first,
      nickname: data.name?.preferred,
      // middle_name: data.name?.middle,
      last_name: data.name?.last,
      suffix: data.name?.suffix,
      gender: data.gender,
      date_of_birth: data.dateOfBirth,
    }
    if (data.phone) {
      createProfileData.phone_numbers = [
        // eslint-disable-next-line id-blacklist
        { number: data.phone, phone_type: 'cellPhone' }
      ]
    }
    if (data.email) {
      createProfileData.email_addresses = [
        { address: data.email }
      ]
    }
    // if (data.addresses) createProfileData.addresses = data.addresses
    if (data.gradYear) createProfileData.graduation_year = data.gradYear

    return this.api.post<Persona>(this.endpoint, createProfileData)
      .pipe(
        first(),
        map(response => response.result)
      )
  }

  public saveVerifiedInfo(personaId: string, orgId: string, payload: any): Observable<VerifiedInformation> {
    return this.api.put<VerifiedInformation>(
      `${this.endpoint}/${personaId}/verified_information`,
      payload,
      { params: { org_id: orgId }, headers: nginV2Headers }
    ).pipe(
      map(response => response.result)
    )
  }

  public getMine(): Observable<ApiResponse<Persona[]>> {
    return this.api.get<Persona[]>('personas/mine')
  }

  public loadMine(reload = false): void {
    if (this._mine && !reload) {
      this._mineSubject.next(this._mine)
      return
    }

    this.getMine()
      .pipe(first())
      .subscribe(
        (data: ApiResponse<Persona[]>) => {
          this._mine = data.result
          this._mineSubject.next(this._mine)
        },
        (error) => this._mineSubject.next([])
      )
  }

  public mine(): Observable<Persona[]> {
    return this._mineSubject.asObservable()
  }
}
