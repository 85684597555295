import { Component, OnInit } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'

@Component({
  selector: 'se-po-select-relationship',
  templateUrl: './select-relationship.component.html',
  styleUrls: ['./select-relationship.component.scss'],
})
export class SelectRelationshipComponent implements OnInit {
  public form: UntypedFormGroup

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public get formValue(): string {
    return this.form.get('selectRelationship').value
  }

  public ngOnInit(): void {
    this.createForm()
  }

  public createForm(): void {
    this.form = this.formBuilder.group(
      { selectRelationship: [null] }
    )
  }
}
