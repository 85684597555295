import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { UserService } from '@se-po/shared-data-access-services'
import { AddChildProfileComponent } from '@se-po/shared-ui-add-child-profile'
import { Subscription } from 'rxjs'
import { SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/UserService'
import { AddGuardianComponent } from '../add-guardian/add-guardian.component'
import { NewPersonSubmitter } from '../new-person-submitter-service'
import { SelectRelationshipComponent } from '../select-relationship/select-relationship.component'

enum NewPersonModalStep {
  SelectRelationship,
  AddChild,
  AddGuardian,
}

@Component({
  selector: 'se-po-new-person-modal',
  templateUrl: './new-person-modal.component.html',
  styleUrls: ['./new-person-modal.component.scss'],
})
export class NewPersonModalComponent implements OnInit, OnDestroy {
  @Input() public profiles: UserProfile[] = []
  @Input() public guardians: RelatedGuardiansResult[] = []

  @Output() public childAdded: EventEmitter<Persona> = new EventEmitter()
  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild('selectRelationship') public selectRelationship: SelectRelationshipComponent
  @ViewChild('childProfile') public childProfile: AddChildProfileComponent
  @ViewChild('modalFooter') public footer: SeFeModalFooterComponent
  @ViewChild('addGuardian') public addGuardian: AddGuardianComponent

  public continueLabel = 'Next'
  public stepEnum: typeof NewPersonModalStep = NewPersonModalStep
  public step: NewPersonModalStep = NewPersonModalStep.SelectRelationship
  public userId: number

  private subscription: Subscription = new Subscription()

  constructor(private userService: UserService, private newPersonService: NewPersonSubmitter) { }

  public get progressComplete(): number {
    return this.step ? 2 : 1
  }

  public ngOnInit(): void {
    this.subscription.add(this.userService.current.subscribe(user => this.userId = user?.id))
    this.userService.findCurrent()
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public back(): void {
    this.step = NewPersonModalStep.SelectRelationship
    this.setContinueButtonLabel()
  }

  public setContinueButtonLabel(): void {
    switch (this.step) {
      case NewPersonModalStep.SelectRelationship:
        this.continueLabel = 'Next'
        break
      case NewPersonModalStep.AddChild:
        this.continueLabel = 'Add Profile'
        break
      case NewPersonModalStep.AddGuardian:
        this.continueLabel = 'Send Invite'
        break
    }
  }

  public continue(): void {
    switch (this.step) {
      case NewPersonModalStep.SelectRelationship:
        this.selectRelationshipContinue()
        break
      case NewPersonModalStep.AddChild:
        this.submitChildProfile()
        break
      case NewPersonModalStep.AddGuardian:
        this.submitGuardianInvite()
        break
    }
    this.setContinueButtonLabel()
  }

  public submitChildProfile(): void {
    if (!this.childProfile.isValid()) return

    this.newPersonService.createChild(this.userId, this.childProfile.value).subscribe(
      result => {
        this.childAdded.emit(result)
        this.modal.close()
      }
    )
  }

  public submitGuardianInvite(): void {
    this.addGuardian.submit()
  }

  public selectRelationshipContinue(): void {
    switch (this.selectRelationship.formValue) {
      case 'addChild':
        this.step = NewPersonModalStep.AddChild
        break
      case 'addGuardian':
        this.step = NewPersonModalStep.AddGuardian
        break
    }
  }

  public open(): void {
    const subProfiles = this.profiles.filter(profile => profile.access !== 'viewer' && profile.relationship === 'other')
    if (subProfiles.length) {
      this.step = NewPersonModalStep.SelectRelationship
    }
    else {
      this.step = NewPersonModalStep.AddChild
    }
    this.setContinueButtonLabel()
    this.modal.open(null)
  }
}
