import { ClareResources as cr } from 'se-resource-types'
import { Adjustment } from './adjustment'

export interface SalePayment extends cr.SalePayment {}

export class SalePayment {
  public constructor(data?: any) {
    Object.assign(this, data || {})
  }

  public get canceled(): boolean {
    return this.status === 'canceled'
  }
  public get pastDue(): boolean {
    return this.due_date_days_past && this.due_date_days_past > 0
  }
  public get unpaid(): boolean {
    return this.status === 'unpaid'
  }

  public get originalAmountStrikethrough(): boolean {
    return Number(this.original_amount) > 0 && this.amount !== this.original_amount
  }
}

export interface SaleActivity extends cr.SaleActivity {}

export class SaleActivity {
  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

export interface SaleItem extends cr.SaleItem {}

export class SaleItem {
  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

export interface SaleRegister extends cr.SaleRegister {}

export class SaleRegister {
  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

export interface Sale extends cr.Sale {}

export class Sale {
  private static PAYMENT_ACCOUNT_TYPES: any = {
    visa : 'Visa',
    mastercard : 'MasterCard',
    discover : 'Discover',
    american_express : 'American Express',
    checking : 'Checking',
    savings : 'Savings',
    check : 'Offline Check',
    cash : 'Offline Cash',
    complimentary : 'Complimentary'
  }

  public items: SaleItem[]
  public registers: SaleRegister[]
  public adjustments: Adjustment[]

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }

  public get payment_plan_account_type(): string {
    if (this.payment_plan_payment_method) {
      return Sale.PAYMENT_ACCOUNT_TYPES[this.payment_plan_payment_method.acct_type]
    }
  }
}
