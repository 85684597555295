<se-fe-modal #addModal (seFeClose)="closeModal()">
    <div seFeModalHeader>
      <se-fe-modal-header seFeDataTitle="Grant Access">
      </se-fe-modal-header>
    </div>
    <div seFeModalContent>
      <se-fe-alert
        *ngIf="submitError"
        style="margin-top: 12px;"
        seFeDataType="error"
        [seFeDismissible]="false">
        Invitation Not Sent
        <se-fe-alert-subheader>
          Action can't be completed, please try again.
        </se-fe-alert-subheader>
      </se-fe-alert>
      <div>
        Select who you would like {{ profile?.user?.self_persona?.first_name }} to have permission to access:
      </div>
      <se-po-guardian-access
        #guardianAccess
        [accountItems]="accountItems"
        [allInvites]="allInvites"
        [inviteItems]="inviteItems"
        [guardians]="guardians"
        [profile]="profile"
        [profiles]="profiles">
      </se-po-guardian-access>
    </div>
    <div seFeModalFooter>
      <se-fe-modal-footer #modalFooter [seFeDataFooterButtons]="modalButtons">
      </se-fe-modal-footer>
    </div>
  </se-fe-modal>
