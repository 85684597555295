import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { seFeIconEnvelopeThin, SeFeIconModule } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { GuardianAccessComponent } from './guardian-access/guardian-access.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeChipModule,
    SeFeFormFieldModule,
    SeFeFormFieldOptionsModule,
    SeFeIconModule.include([seFeIconEnvelopeThin]),
    SeFeListModule,
    SeFeMenuModule,
    SeFePopoverModule,
    SeFeTooltipModule,
    SharedUiPersonaAvatarModule
  ],
  declarations: [GuardianAccessComponent],
  exports: [GuardianAccessComponent],
})
export class SeMySportsengineFeatureGuardianAccessModule {}
