import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { DateTime } from 'luxon'

import { SurveyResultService } from '@se-po/shared-data-access-services'
import { SurveyResult } from 'se-resource-types/dist/lib/Ngin'

@Component({
  selector: 'se-po-registration-list',
  templateUrl: './registration-list.component.html',
  styleUrls: ['./registration-list.component.scss'],
})
export class RegistrationListComponent implements OnChanges {

  @Input() public personaId: string | number
  @Input() public access: 'viewer' | 'manager' | 'owner'
  @Output() public hasResults: EventEmitter<boolean> = new EventEmitter()

  public listItems: any[]
  public loaded = false

  constructor(
    private surveyResultService: SurveyResultService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    const refresh = Object.values(changes).find((change) => change.firstChange || change.currentValue !== change.previousValue)
    if (refresh) {
      this.setListItems()
    }
  }

  private async setListItems(): Promise<void> {
    this.loaded = false
    const surveys = await this.getSurveys()
    this.listItems = surveys.map((survey: SurveyResult) => ({
      subtitle: [DateTime.fromISO(survey.placed_at).toFormat('MMM d, yyyy'), survey.organization_name],
      title: survey.survey_name,
      url: `/user/registrations/${survey.id}?siteId=${survey.site_id}`
    }))
    this.loaded = true
    this.hasResults.emit(!!this.listItems.length)
  }

  private getSurveys(): Promise<SurveyResult[]> {
    if (!this.personaId) {
      return Promise.resolve([])
    }
    return this.surveyResultService.findByPersonaId(this.personaId, 5).toPromise()
  }

}
