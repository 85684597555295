<div class="child-info">

  <div class="child-info-icon">
    <se-fe-icon
      seFeDataName="spot_jersey"
      seFeDataSize="950"
      seFeDataAriaLabel="child"
    ></se-fe-icon>
  </div>
  <div class="child-info-title">
    <se-po-highlight-label
      label="Child's Info"
      theme="child"
    ></se-po-highlight-label>
  </div>

  <div role="content" class="child-info-content">
    <div *ngIf="!ready">
      <se-fe-spinner
        seFeDataSize="950"
        [seFeDataInverted]="false"
        [seFeDataCenter]="true"
      ></se-fe-spinner>
    </div>
    <form
      *ngIf="ready"
      [formGroup]="form"
      novalidate>

      <section>
        <se-po-persona-info #childInfo
          formControlName="childInfo"
          [requiredFields]="['name', 'gender', 'dateOfBirth']"
          [optionalFields]="['gradYear']"
          [labels]="{name: 'Child\'s Name'}"
        ></se-po-persona-info>
      </section>
    </form>
  </div>
</div>
