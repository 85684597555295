import { forwardRef, Injectable } from '@angular/core'
import { PersonaInfo } from '@se-po/shared-data-access-models'
import { HouseholdGuardianInvitesService, UserPersonaService } from '@se-po/shared-data-access-services'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CentralServiceResources as cs } from 'se-resource-types'
import { Persona } from 'se-resource-types/dist/lib/UserService'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => NewPersonService),
})

export abstract class NewPersonSubmitter {
  public abstract error: string
  public abstract createGuardian(
    access: string, to: string, persona_id: number, initiator: string): Observable<cs.Households.PersonaGuardianInvite>
  public abstract createChild(userId: number, childInfo: PersonaInfo): Observable<Persona>
}

@Injectable({
    providedIn: 'root',
  })

  export class NewPersonService implements NewPersonSubmitter {
    public error: string
    private endpoint = 'v3/households/guardian_invites'

    constructor(
      private inviteService: HouseholdGuardianInvitesService,
      private userPersonaService: UserPersonaService
    ) {
        // noop
    }

    public createGuardian(
      access: string, to: string, persona_id: number, initiator: string): Observable<cs.Households.PersonaGuardianInvite> {
      return this.inviteService.create(access, to, persona_id, initiator).pipe(
        map(result => result)
      )
    }

    public createChild(userId: number, childInfo: PersonaInfo): Observable<Persona> {
      return this.userPersonaService.create(userId, childInfo)
    }
  }
