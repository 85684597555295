<se-fe-card
  class="eventCard"
  [style]="'border-color:' + cardAccentColor +';'">
  <se-fe-card-content class="cardContent">
    <div *ngIf="event.tags && event.tags.length > 0">
      <span *ngFor="let tag of event.tags" [class]="'pl-tag--' + tag.color">{{ tag.value.toUpperCase() }}</span>
    </div>
    <p *ngIf="(!event.tags || event.tags.length === 0) && range">{{ range }}</p>
    <se-fe-header>
      <a seFeHeaderTitle
        seFeLink="low"
        *ngIf="event.titleAttributes"
        [href]="event.titleAttributes.href || undefined "
        class="se-event-card__title">
        {{ event.title }}
      </a>
      <div seFeHeaderTitle
        *ngIf="!event.titleAttributes"
        class="se-event-card__title">
        {{ event.title }}
      </div>
      <div seFeHeaderSubtitle
        *ngIf="event.subtitle"
        class="se-event-card__subtitle">
        {{ event.subtitle }}
      </div>
      <div *ngIf="playChipOpts"  seFeHeaderActions>
        <se-fe-play-chip
          [attr.seFeDataOptions]="playChipOpts">
        </se-fe-play-chip>
      </div>
    </se-fe-header>

    <a *ngIf="event.locationAttributes"
      seFeLink="low"
      [href]="event.locationAttributes.href || undefined"
      class="se-event-card__location">
      {{ event.location }}
    </a>
    <div *ngIf="!event.locationAttributes && event.location"
      class="se-event-card__location">
      {{ event.location }}
    </div>
    <app-rsvp-list
      [rsvps]="rsvps || []"
      [hasAllRsvps]="hasAllRsvps()"
      [headerAvatars]="shownAvatars"
      [additionalAvatars]="avatarsNotShown"
      (onRsvp)="emitEvent($event)">
    </app-rsvp-list>
  </se-fe-card-content>
</se-fe-card>
